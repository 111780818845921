import React from "react";
import classes from "./AddContractPage.module.scss";

const AddContractPage = () => (
  <div className={classes.wrapper}>
    <h1>Add Contract</h1>
  </div>
);

export default AddContractPage;
