import React from "react";
import classes from "./AddInvoicePage.module.scss";

const AddInvoicePage = () => (
  <div className={classes.wrapper}>
    <h1>Add Invoice</h1>
  </div>
);

export default AddInvoicePage;
