export const initialValues = {
  legal: "",
  itn: "",
  address: "",
  iban: "",
  bank: "",
  bic: "",
  noticea: "",
  noticeb: "",
  contract: "",
  contractdate: new Date(Date.now()),
  phone: "",
};
