const COLORS = {
  PRIMARY: "#3F6A73",
  SECONDARY: "#285059",
  TRETIARY: "#5CE6B8",
  ACCENT: "#3DCCB4",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  WARNING: "#CC3300",
  LIGHTGRAY: "#F3F3F3",
};

export default COLORS;
